$(document).on("click", ".link_to_page", (e) => {
    e.preventDefault(); // Prevent default link behavior
    let el = e.currentTarget;
    $(".side-menu__link--active").removeClass("side-menu__link--active");
    $(el).addClass("side-menu__link--active");

    let title = $(el).data("title");
    let is_public = $(el).data("is_public");
    let is_link = $(el).data("is_link");

    let url;
    if (is_link) {
        url = "/app/List/" + frappe.router.slug(title);
    } else {
        url = "/app/" + (is_public ? frappe.router.slug(title) : "private/" + frappe.router.slug(title));
    }

    // Open in new tab if Ctrl, Cmd, or Middle Click is pressed; otherwise, open in the same tab
    if (e.ctrlKey || e.metaKey || e.button === 1) {
        window.open(url, "_blank");
    } else {
        frappe.set_route(url);
    }
});